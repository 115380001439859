exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apparel-leadership-jsx": () => import("./../../../src/pages/apparel-leadership.jsx" /* webpackChunkName: "component---src-pages-apparel-leadership-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-corporate-jsx": () => import("./../../../src/pages/corporate.jsx" /* webpackChunkName: "component---src-pages-corporate-jsx" */),
  "component---src-pages-corporate-leadership-jsx": () => import("./../../../src/pages/corporate-leadership.jsx" /* webpackChunkName: "component---src-pages-corporate-leadership-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-footprint-jsx": () => import("./../../../src/pages/footprint.jsx" /* webpackChunkName: "component---src-pages-footprint-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legacy-jsx": () => import("./../../../src/pages/legacy.jsx" /* webpackChunkName: "component---src-pages-legacy-jsx" */),
  "component---src-pages-news-events-jsx": () => import("./../../../src/pages/news-events.jsx" /* webpackChunkName: "component---src-pages-news-events-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-our-capabilities-jsx": () => import("./../../../src/pages/our-capabilities.jsx" /* webpackChunkName: "component---src-pages-our-capabilities-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-template-blogs-template-jsx": () => import("./../../../src/pages-template/BlogsTemplate.jsx" /* webpackChunkName: "component---src-pages-template-blogs-template-jsx" */),
  "component---src-pages-template-events-template-jsx": () => import("./../../../src/pages-template/EventsTemplate.jsx" /* webpackChunkName: "component---src-pages-template-events-template-jsx" */),
  "component---src-pages-template-news-template-jsx": () => import("./../../../src/pages-template/NewsTemplate.jsx" /* webpackChunkName: "component---src-pages-template-news-template-jsx" */)
}

